@import "parameters";

.footer {
    padding: 1rem;

    .footnote {
        padding: 2rem 0 1rem 0;
        display: flex;
        justify-content: center;

        p {
            margin: 0;
        }
    }

    .socials {
        .link {
            padding-right: 1rem;
        }

        a {
            color: $dark-blue;
        }
    }

    .grow {
        transition: all .2s ease-in-out;

        &:hover {
            transform: scale(1.2);
        }
    }
}