@import "parameters";

.hero {
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    color: $dark-blue;
    position: absolute;
    top: 50%;
    padding: 0 0.8rem;
    transform: translateY(-70%);

    h3 {
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: 300;
    }

    h1 {
        font-size: 5rem;
        margin-top: 1rem;
        font-weight: bold;
        text-shadow: 0 15px 15px rgba(0, 0, 0, .16);
    }

    h4 {
        margin-top: 1rem;
        letter-spacing: 3px;
        text-transform: uppercase;
    }

    p {
        font-size: .8rem;
        margin-top: 1rem;
    }
}

.scroll {
    text-transform: uppercase;
    color: $grey;
    letter-spacing: 3px;
    font-size: 12px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    top: 90%;
    left: 0;

    p {
        margin-bottom: .5rem;
    }
}

.footer {
    text-transform: uppercase;
    color: $grey;
    letter-spacing: 3px;
    font-size: 12px;
}

.purple {
    color: $purple;
}

.intro {
    margin-right: 1rem;

    @media (max-width: $mobile-max-width) {
        margin-right: 0;
    }
}

.sub-heading {
    display: flex;
}

.carrot {
    max-height: 1.5rem;
    padding-left: .8rem;
    align-self: center;
}

.bounce {
    animation: key1 .3s ease infinite alternate;
}

@keyframes key1 {
    0% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(-.1rem);
    }
}