// Colours:
$light-blue: #f4f9fc;
$purple: #7f00e0;
$aqua: #aadcec;
$dark-blue: #0f1b61;
$bright-blue: #aadcec;
$secondary-blue: #aadcec;
$grey: #919795;

// sizes
$mobile-max-width: 660px;
