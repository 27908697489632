@import "parameters";

.skills {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;

  li {
    display: list-item;
    padding: 0.25rem 0.75rem;
    margin-top: 0.5rem;
    background-color: $bright-blue;
    border-radius: 9999px;
    margin-right: .25rem;
    font-size: 12px;
    font-weight: 500;
  }
}

.section {
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  color: $dark-blue;
  margin-bottom: 2rem;
  padding: 2rem 2rem 0 2rem;

  h5 {
    letter-spacing: 1px;
  }
}

.experience-card {
  background-color: $light-blue;
  background-image: linear-gradient(135deg, #f4f9fc 38%, #aadcec54);
  margin-bottom: 2rem;
  border-radius: 2rem;
  padding: 1.8rem 1rem;

  h5 {
    font-size: 1rem;
  }
}

.summary-heading {
  padding-top: 1rem;
}

.summary-list {
  padding-bottom: 1rem;
}